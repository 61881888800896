input.autocompleteInput[type="search"]::-webkit-search-decoration,
input.autocompleteInput[type="search"]::-webkit-search-cancel-button,
input.autocompleteInput[type="search"]::-webkit-search-results-button,
input.autocompleteInput[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* overrides vtmn and forces items to be full width */
.dropDownItems {
  inline-size: -webkit-fill-available !important;
}
.personalDetails span {
  font-size: 2rem;
  font-weight: bold;
}

.dropDownItem {
  box-sizing: border-box;
  background-color: var(--vp-semantic-color-background-main);
  padding: var(--vtmn-spacing_3);
  margin: 0;
  width: 100%;
  block-size: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  position: relative;
  height: auto;
}

.dropDownItem:hover {
  border-bottom: 2px solid var(--vp-semantic-color-border-neutral, grey);
  background-color: var(--vp-semantic-color-alpha-quiet-s, lightgrey);
  cursor: pointer;
}
