.css__content-block:hover {
  background-color: #323894;
}
.css__content-block {
  background-color: #3643ba;
  color: white;
  font-weight: bolder;
  text-align: center;
}
/* This will apply only in Firefox */
@-moz-document url-prefix() {
  .css__content-block {
    width: 100%; /* Only for Firefox */
  }
}
@media only screen and (max-width: 319px) {
  .css__content-block {
    height: 40vw !important;
    width: -webkit-fill-available;
    font-size: small;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .css__content-block {
    height: 40vw !important;
    width: -webkit-fill-available;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (479 - 320)));
  }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .css__content-block {
    height: 30vw !important;
    width: -webkit-fill-available;
    font-size: calc(14px + (18 - 14) * ((100vw - 480px) / (599 - 480)));
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .css__content-block {
    height: 18vw !important;
    width: -webkit-fill-available;
    font-size: calc(14px + (18 - 14) * ((100vw - 600px) / (991 - 600)));
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .css__content-block {
    height: 15vw !important;
    width: -webkit-fill-available;
    font-size: calc(14px + (18 - 14) * ((100vw - 992px) / (1199 - 992)));
  }
}

@media only screen and (min-width: 1200px) {
  .css__content-block {
    height: 12vw !important;
    width: -webkit-fill-available;
  }
}
