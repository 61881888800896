.questionniare-question {
  flex: 2;
}

.questionniare-options {
  flex: 1;
  justify-content: space-around !important;
}

.questionniare-checkbox {
  height: 20px;
  width: 20px;
}

.lbl-questionniare-checkbox {
  display: inline-block;
  padding: 1rem;
}

input[type="checkbox"]:checked {
  accent-color: #3643ba;
}
