@import "@vtmn-play/design-tokens";
@import "@vtmn-play/css";

.card {
  &__items {
    gap: 3rem;
  }

  .card__item--image {
    max-width: 3.6rem;
  }

  &__item {
    min-height: 27rem;
    box-shadow: 0px 6px 6px rgba(0, 83, 125, 0.1);

    &--img {
      max-width: 3.6rem;
    }
  }

  &.theme-blue {
    background: blue;
  }

  &.theme-red {
    background: red;
  }

  &.theme-green {
    background: #eafbe8;
  }

  &.theme-white {
    background: white;
  }
}

/* added because vtmn classes are not avaible */
.vtmn-py-7 {
  padding-top: var(--vtmn-spacing_7);
  padding-bottom: var(--vtmn-spacing_7);
}

.vtmn-py-9 {
  padding-top: var(--vtmn-spacing_9);
  padding-bottom: var(--vtmn-spacing_9);
}

.vtmn-py-10 {
  padding-top: var(--vtmn-spacing_10);
  padding-bottom: var(--vtmn-spacing_10);
}
