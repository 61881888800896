body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* filepond css */
.filepond--credits {
  display: none;
}
.filepond--root {
  margin: auto;
  /* max-height: 20vh; */
  width: 90%;
  overflow-y: scroll;
  border-radius: 0.25rem;
  background-color: #f2f2f2;
}
/* .filepond--list {
  overflow: scroll;
} */
.filepond--item {
  width: calc(50% - 0.5em);
  touch-action: pan-y;
}

.filepond--panel-root {
  background-color: #f2f2f2;
}
/* the text color of the file status and info labels */
.filepond--file {
  color: rgb(255, 255, 255);
}
/* Action btn and title overlay */
/* .filepond--image-preview-overlay-idle {
} */
/* error state color */
/* [data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: red;
}
[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: green;
} */
