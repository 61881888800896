.vtmn-list_start-visual {
  -webkit-margin-start: 0px !important;
  margin-inline-start: 0rem !important;
}
.inspectionTitle span {
  font-size: 2rem;
  font-weight: bold;
}

.vtmn-text-input_container {
  width: 100%;
}

.scanner-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.order-info-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.order-info-popup {
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 70vh;
  overflow-y: auto;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
